import { render, staticRenderFns } from "./Trends.vue?vue&type=template&id=933962a0&scoped=true&"
import script from "./Trends.vue?vue&type=script&lang=js&"
export * from "./Trends.vue?vue&type=script&lang=js&"
import style0 from "./Trends.vue?vue&type=style&index=0&id=933962a0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "933962a0",
  null
  
)

export default component.exports